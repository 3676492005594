import { ProjectData, Unit } from 'shared/models';
import { Math_round } from './calculation/mathRound';
import { ConvertUnit } from './calculation/unitConverter';
import { METRIC_DEFAULTS } from 'shared/constants';
import { LateralValues, MainlineValues, SubmainValues, SystemSummaryValues } from 'shared/slices';

type EDCInputValues = {
  emitterSpacing: number | string;
  lateralSpacing: number | string;
  bedWidth: number | string;
};
type EDCResultValues = {
  minimum: number | string;
  maximum: number | string;
  average: number | string;
};
type EDCTableValues = {
  max1: number | string;
  max2: number | string;
  min1: number | string;
  min2: number | string;
  step1: number | string;
  step2: number | string;
};

export const getConvertedSystemSummaryValues = ({
                                                  systemSummaryValues,
                                                  sourceUnits,
                                                  destUnits,
                                                }: {
  systemSummaryValues: SystemSummaryValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    flowRate: Math_round(
      ConvertUnit(systemSummaryValues.flowRate as number, sourceUnits.flow, destUnits.flow, null),
      3,
    ),
    avgDischarge: Math_round(
      ConvertUnit(systemSummaryValues.avgDischarge as number, sourceUnits.totalFlow, destUnits.totalFlow, null),
      3,
    ),
    avgIrrigationRate: Math_round(
      ConvertUnit(systemSummaryValues.avgIrrigationRate as number, sourceUnits.appnRate, destUnits.appnRate, null),
      3,
    ),
  };
};

export const getConvertedMainlineValues = ({
                                             mainlineValues,
                                             sourceUnits,
                                             destUnits,
                                           }: {
  mainlineValues: MainlineValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    pumpPressure: Math_round(
      ConvertUnit(mainlineValues.pumpPressure as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    pipesHeadloss: Math_round(
      ConvertUnit(mainlineValues.pipesHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    allowableHeadloss: Math_round(
      ConvertUnit(mainlineValues.allowableHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    pumpHeadloss: Math_round(
      ConvertUnit(mainlineValues.pumpHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    valveHeadloss: Math_round(
      ConvertUnit(mainlineValues.valveHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    length: Math_round(ConvertUnit(mainlineValues.length as number, sourceUnits.length, destUnits.length, null), 3),
    maxVelocity: Math_round(
      ConvertUnit(mainlineValues.maxVelocity as number, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    flow: Math_round(ConvertUnit(mainlineValues.flow as number, sourceUnits.totalFlow, destUnits.totalFlow, null), 3),
  };
};

export const getConvertedSubmainValues = ({
                                            submainValues,
                                            sourceUnits,
                                            destUnits,
                                          }: {
  submainValues: SubmainValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    allowedHeadloss: Math_round(
      ConvertUnit(submainValues.allowedHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    firstLateral: Math_round(
      ConvertUnit(submainValues.firstLateral as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    lastLateral: Math_round(
      ConvertUnit(submainValues.lastLateral as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    valvePressure: Math_round(
      ConvertUnit(submainValues.valvePressure as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    totalHeadloss: Math_round(
      ConvertUnit(submainValues.totalHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
  };
};

export const getConvertedLateralCalculations = ({
                                                  lateralValues,
                                                  sourceUnits,
                                                  destUnits,
                                                }: {
  lateralValues: LateralValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    resultMaxVelocity: Math_round(
      ConvertUnit(lateralValues.resultMaxVelocity as number, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    resultTotalHeadloss: Math_round(
      ConvertUnit(lateralValues.resultTotalHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    resultTotalFlow: Math_round(
      ConvertUnit(lateralValues.resultTotalFlow as number, sourceUnits.totalFlow, destUnits.totalFlow, null),
      3,
    ),
    resultPMin: Math_round(
      ConvertUnit(lateralValues.resultPMin as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    resultPMax: Math_round(
      ConvertUnit(lateralValues.resultPMax as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    resultMaxLength: Math_round(
      ConvertUnit(lateralValues.resultMaxLength as number, sourceUnits.length, destUnits.length, null),
      3,
    ),
    resultMaxLengthMaxVelocity: Math_round(
      ConvertUnit(lateralValues.resultMaxLengthMaxVelocity as number, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    resultMaxLengthTotalHeadloss: Math_round(
      ConvertUnit(lateralValues.resultMaxLengthTotalHeadloss as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    resultMaxLengthTotalFlow: Math_round(
      ConvertUnit(lateralValues.resultMaxLengthTotalFlow as number, sourceUnits.totalFlow, destUnits.totalFlow, null),
      3,
    ),
    resultMaxLengthPMin: Math_round(
      ConvertUnit(lateralValues.resultMaxLengthPMin as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    resultMaxLengthPMax: Math_round(
      ConvertUnit(lateralValues.resultMaxLengthPMax as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
  };
};

export const getConvertedEDCTableValues = ({
                                             edcValues,
                                             sourceUnits,
                                             destUnits,
                                           }: {
  edcValues: EDCTableValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    max1: Math_round(
      ConvertUnit(edcValues.max1 as number, sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    max2: Math_round(
      ConvertUnit(edcValues.max2 as number, sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    min1: Math_round(
      ConvertUnit(edcValues.min1 as number, sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    min2: Math_round(
      ConvertUnit(edcValues.min2 as number, sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    step1: Math_round(
      ConvertUnit(edcValues.step1 as number, sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    step2: Math_round(
      ConvertUnit(edcValues.step2 as number, sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
  };
};

export const getConvertedEDCResultValues = ({
                                              edcValues,
                                              sourceUnits,
                                              destUnits,
                                            }: {
  edcValues: EDCResultValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    minimum: Math_round(ConvertUnit(edcValues.minimum as number, sourceUnits.appnRate, destUnits.appnRate, null), 3),
    maximum: Math_round(ConvertUnit(edcValues.maximum as number, sourceUnits.appnRate, destUnits.appnRate, null), 3),
    average: Math_round(ConvertUnit(edcValues.average as number, sourceUnits.appnRate, destUnits.appnRate, null), 3),
  };
};

export const getConvertedEDCInputValues = ({
                                             edcValues,
                                             sourceUnits,
                                             destUnits,
                                           }: {
  edcValues: EDCInputValues;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    emitterSpacing: Math_round(
      ConvertUnit(Number(edcValues.emitterSpacing), sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    lateralSpacing: Math_round(
      ConvertUnit(Number(edcValues.lateralSpacing), sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    bedWidth: Math_round(
      ConvertUnit(Number(edcValues.bedWidth), sourceUnits.length, destUnits.length, null),
      3,
    ),
  };
};

export const getConvertedProjectValues = ({
                                            projectData,
                                            sourceUnits,
                                            destUnits,
                                          }: {
  projectData: ProjectData;
  sourceUnits: Omit<Unit, 'userID'>;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    lateralInletPressure: Math_round(
      ConvertUnit(projectData.lateralInletPressure as number, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    lateralInletDia: Math_round(
      ConvertUnit(+projectData.lateralInletDia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    emitterNominalPressure: Math_round(
      ConvertUnit(projectData.emitterNominalPressure, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    emitterNominalFlow: Math_round(
      ConvertUnit(projectData.emitterNominalFlow, sourceUnits.flow, destUnits.flow, null),
      3,
    ),
    emitterSpacing: Math_round(
      ConvertUnit(+projectData.emitterSpacing, sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      4,
    ),
    runlengthStart: Math_round(ConvertUnit(projectData.runlengthStart, sourceUnits.length, destUnits.length, null), 3),
    runlengthEnd: Math_round(ConvertUnit(projectData.runlengthEnd, sourceUnits.length, destUnits.length, null), 3),
    runlengthIncrement: Math_round(
      ConvertUnit(projectData.runlengthIncrement, sourceUnits.length, destUnits.length, null),
      3,
    ),
    flushingVelocity: Math_round(
      ConvertUnit((projectData?.flushingVelocity as number) ?? 0, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    emitterPMin: Math_round(ConvertUnit(+projectData.emitterPMin, sourceUnits.pressure, destUnits.pressure, null), 3),
    emitterPMax: Math_round(ConvertUnit(+projectData.emitterPMax, sourceUnits.pressure, destUnits.pressure, null), 3),
    totalFlow: Math_round(ConvertUnit(+projectData.totalFlow, sourceUnits.totalFlow, destUnits.totalFlow, null), 3),
    flow1: Math_round(ConvertUnit(+projectData.flow1, sourceUnits.totalFlow, destUnits.totalFlow, null), 3),
    flow2: Math_round(ConvertUnit(+projectData.flow2, sourceUnits.totalFlow, destUnits.totalFlow, null), 3),
    lateralLength2: Math_round(ConvertUnit(+projectData.lateralLength2, sourceUnits.length, destUnits.length, null), 3),
    manifoldAllowableHeadloss: Math_round(
      ConvertUnit(projectData.manifoldAllowableHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    firstLateralDistance: Math_round(
      ConvertUnit(+projectData.firstLateralDistance, sourceUnits.length, destUnits.length, null),
      3,
    ),
    totalManifoldLength: Math_round(
      ConvertUnit(+projectData.totalManifoldLength, sourceUnits.length, destUnits.length, null),
      3,
    ),
    manifoldSpacing: Math_round(
      ConvertUnit(+projectData.manifoldSpacing, sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      4,
    ),
    manifoldPipe1Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    manifoldPipe2Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    manifoldPipe3Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    manifoldPipe1Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldPipe2Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldPipe3Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldHeadloss: Math_round(
      ConvertUnit(projectData.manifoldHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    manifoldMaximumVelocity: Math_round(
      ConvertUnit(+projectData.manifoldMaximumVelocity, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    valvePressure: Math_round(
      ConvertUnit(projectData.valvePressure, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlineFlow: Math_round(
      ConvertUnit(projectData.mainlineFlow, sourceUnits.totalFlow, destUnits.totalFlow, null),
      3,
    ),
    mainlinePumpPressure: Math_round(
      ConvertUnit(projectData.mainlinePumpPressure, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePumpHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePumpHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlineValveHeadloss: Math_round(
      ConvertUnit(projectData.mainlineValveHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePipe1Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    mainlinePipe2Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    mainlinePipe3Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Length, sourceUnits.length, destUnits.length, null),
      3,
    ),
    mainlinePipe1Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlinePipe2Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlinePipe3Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Dia, sourceUnits.pipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlineAllowedHeadloss: Math_round(
      ConvertUnit(projectData.mainlineAllowedHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePipeHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePipeHeadloss, sourceUnits.pressure, destUnits.pressure, null),
      3,
    ),
    mainlineLength: Math_round(ConvertUnit(projectData.mainlineLength, sourceUnits.length, destUnits.length, null), 3),
    mainlineMaxVelocity: Math_round(
      ConvertUnit(projectData.mainlineMaxVelocity, sourceUnits.velocity, destUnits.velocity, null),
      3,
    ),
    zoneEmitterSpacing: Math_round(
      ConvertUnit(+projectData.zoneEmitterSpacing, sourceUnits.emitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    zoneLateralSpacing: Math_round(
      ConvertUnit(projectData.zoneLateralSpacing, sourceUnits.lateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    zoneEmitterFlowRate: Math_round(
      ConvertUnit(projectData.zoneEmitterFlowRate, sourceUnits.flow, destUnits.flow, null),
      3,
    ),
    zoneIrrigationArea: Math_round(
      ConvertUnit(+projectData.zoneIrrigationArea, sourceUnits.area, destUnits.area, null),
      3,
    ),
    avgPeakConsumptiveUse: Math_round(
      ConvertUnit(+projectData.avgPeakConsumptiveUse, sourceUnits.appnDepth, destUnits.appnDepth, null),
      3,
    ),
    sourceFlowRate: Math_round(
      ConvertUnit(+projectData.sourceFlowRate, sourceUnits.totalFlow, destUnits.totalFlow, null),
      3,
    ),
  };
};

export const getConvertedProjectValuesFromBE = ({
                                                  projectData,
                                                  destUnits,
                                                }: {
  projectData: ProjectData;
  destUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    lateralInletPressure: Math_round(
      ConvertUnit(projectData.lateralInletPressure as number, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    lateralInletDia: Math_round(
      ConvertUnit(+projectData.lateralInletDia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    emitterNominalPressure: Math_round(
      ConvertUnit(projectData.emitterNominalPressure, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    emitterNominalFlow: Math_round(
      ConvertUnit(projectData.emitterNominalFlow, METRIC_DEFAULTS.Flow, destUnits.flow, null),
      3,
    ),
    emitterSpacing: Math_round(
      ConvertUnit(+projectData.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    runlengthStart: Math_round(
      ConvertUnit(projectData.runlengthStart, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    runlengthEnd: Math_round(ConvertUnit(projectData.runlengthEnd, METRIC_DEFAULTS.Length, destUnits.length, null), 3),
    runlengthIncrement: Math_round(
      ConvertUnit(projectData.runlengthIncrement, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    flushingVelocity: Math_round(
      ConvertUnit((projectData?.flushingVelocity as number) ?? 0, METRIC_DEFAULTS.Velocity, destUnits.velocity, null),
      3,
    ),
    emitterPMin: Math_round(
      ConvertUnit(+projectData.emitterPMin, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    emitterPMax: Math_round(
      ConvertUnit(+projectData.emitterPMax, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    totalFlow: Math_round(ConvertUnit(+projectData.totalFlow, METRIC_DEFAULTS.TotalFlow, destUnits.totalFlow, null), 3),
    flow1: Math_round(ConvertUnit(+projectData.flow1, METRIC_DEFAULTS.TotalFlow, destUnits.totalFlow, null), 3),
    flow2: Math_round(ConvertUnit(+projectData.flow2, METRIC_DEFAULTS.TotalFlow, destUnits.totalFlow, null), 3),
    lateralLength2: Math_round(
      ConvertUnit(+projectData.lateralLength2, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    manifoldAllowableHeadloss: Math_round(
      ConvertUnit(projectData.manifoldAllowableHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    firstLateralDistance: Math_round(
      ConvertUnit(+projectData.firstLateralDistance, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    totalManifoldLength: Math_round(
      ConvertUnit(+projectData.totalManifoldLength, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    manifoldSpacing: Math_round(
      ConvertUnit(+projectData.manifoldSpacing, METRIC_DEFAULTS.LateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    manifoldPipe1Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    manifoldPipe2Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    manifoldPipe3Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    manifoldPipe1Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldPipe2Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldPipe3Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    manifoldHeadloss: Math_round(
      ConvertUnit(projectData.manifoldHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    manifoldMaximumVelocity: Math_round(
      ConvertUnit(+projectData.manifoldMaximumVelocity, METRIC_DEFAULTS.Velocity, destUnits.velocity, null),
      3,
    ),
    valvePressure: Math_round(
      ConvertUnit(projectData.valvePressure, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlineFlow: Math_round(
      ConvertUnit(projectData.mainlineFlow, METRIC_DEFAULTS.TotalFlow, destUnits.totalFlow, null),
      3,
    ),
    mainlinePumpPressure: Math_round(
      ConvertUnit(projectData.mainlinePumpPressure, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePumpHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePumpHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlineValveHeadloss: Math_round(
      ConvertUnit(projectData.mainlineValveHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePipe1Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    mainlinePipe2Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    mainlinePipe3Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Length, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    mainlinePipe1Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlinePipe2Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlinePipe3Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Dia, METRIC_DEFAULTS.PipeDiameter, destUnits.pipeDiameter, null),
      3,
    ),
    mainlineAllowedHeadloss: Math_round(
      ConvertUnit(projectData.mainlineAllowedHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlinePipeHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePipeHeadloss, METRIC_DEFAULTS.Pressure, destUnits.pressure, null),
      3,
    ),
    mainlineLength: Math_round(
      ConvertUnit(projectData.mainlineLength, METRIC_DEFAULTS.Length, destUnits.length, null),
      3,
    ),
    mainlineMaxVelocity: Math_round(
      ConvertUnit(projectData.mainlineMaxVelocity, METRIC_DEFAULTS.Velocity, destUnits.velocity, null),
      3,
    ),
    zoneEmitterSpacing: Math_round(
      ConvertUnit(+projectData.zoneEmitterSpacing, METRIC_DEFAULTS.EmitterSpacing, destUnits.emitterSpacing, null),
      3,
    ),
    zoneLateralSpacing: Math_round(
      ConvertUnit(projectData.zoneLateralSpacing, METRIC_DEFAULTS.LateralSpacing, destUnits.lateralSpacing, null),
      3,
    ),
    zoneEmitterFlowRate: Math_round(
      ConvertUnit(projectData.zoneEmitterFlowRate, METRIC_DEFAULTS.Flow, destUnits.flow, null),
      3,
    ),
    zoneIrrigationArea: Math_round(
      ConvertUnit(+projectData.zoneIrrigationArea, METRIC_DEFAULTS.Area, destUnits.area, null),
      3,
    ),
    avgPeakConsumptiveUse: Math_round(
      ConvertUnit(+projectData.avgPeakConsumptiveUse, METRIC_DEFAULTS.AppnDepth, destUnits.appnDepth, null),
      3,
    ),
    sourceFlowRate: Math_round(
      ConvertUnit(+projectData.sourceFlowRate, METRIC_DEFAULTS.TotalFlow, destUnits.totalFlow, null),
      3,
    ),
  };
};

export const getConvertedProjectDataForSave = ({
                                                 projectData,
                                                 sourceUnits,
                                               }: {
  projectData: ProjectData;
  sourceUnits: Omit<Unit, 'userID'>;
}) => {
  return {
    ...projectData,
    lateralInletPressure: Math_round(
      ConvertUnit(projectData.lateralInletPressure as number, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    lateralInletDia: Math_round(
      ConvertUnit(+projectData.lateralInletDia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    emitterNominalPressure: Math_round(
      ConvertUnit(projectData.emitterNominalPressure, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    emitterNominalFlow: Math_round(
      ConvertUnit(projectData.emitterNominalFlow, sourceUnits.flow, METRIC_DEFAULTS.Flow, null),
      3,
    ),
    emitterSpacing: Math_round(
      ConvertUnit(+projectData.emitterSpacing, sourceUnits.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null),
      3,
    ),
    runlengthStart: Math_round(
      ConvertUnit(projectData.runlengthStart, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    runlengthEnd: Math_round(
      ConvertUnit(projectData.runlengthEnd, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    runlengthIncrement: Math_round(
      ConvertUnit(projectData.runlengthIncrement, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    flushingVelocity: Math_round(
      ConvertUnit((projectData?.flushingVelocity as number) ?? 0, sourceUnits.velocity, METRIC_DEFAULTS.Velocity, null),
      3,
    ),
    emitterPMin: Math_round(
      ConvertUnit(+projectData.emitterPMin, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    emitterPMax: Math_round(
      ConvertUnit(+projectData.emitterPMax, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    totalFlow: Math_round(
      ConvertUnit(+projectData.totalFlow, sourceUnits.totalFlow, METRIC_DEFAULTS.TotalFlow, null),
      3,
    ),
    flow1: Math_round(ConvertUnit(+projectData.flow1, sourceUnits.totalFlow, METRIC_DEFAULTS.TotalFlow, null), 3),
    flow2: Math_round(ConvertUnit(+projectData.flow2, sourceUnits.totalFlow, METRIC_DEFAULTS.TotalFlow, null), 3),
    lateralLength2: Math_round(
      ConvertUnit(+projectData.lateralLength2, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    manifoldAllowableHeadloss: Math_round(
      ConvertUnit(projectData.manifoldAllowableHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    firstLateralDistance: Math_round(
      ConvertUnit(+projectData.firstLateralDistance, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    totalManifoldLength: Math_round(
      ConvertUnit(+projectData.totalManifoldLength, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    manifoldSpacing: Math_round(
      ConvertUnit(+projectData.manifoldSpacing, sourceUnits.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null),
      3,
    ),
    manifoldPipe1Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    manifoldPipe2Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    manifoldPipe3Length: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    manifoldPipe1Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe1Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    manifoldPipe2Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe2Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    manifoldPipe3Dia: Math_round(
      ConvertUnit(+projectData.manifoldPipe3Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    manifoldHeadloss: Math_round(
      ConvertUnit(projectData.manifoldHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    manifoldMaximumVelocity: Math_round(
      ConvertUnit(+projectData.manifoldMaximumVelocity, sourceUnits.velocity, METRIC_DEFAULTS.Velocity, null),
      3,
    ),
    valvePressure: Math_round(
      ConvertUnit(projectData.valvePressure, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlineFlow: Math_round(
      ConvertUnit(projectData.mainlineFlow, sourceUnits.totalFlow, METRIC_DEFAULTS.TotalFlow, null),
      3,
    ),
    mainlinePumpPressure: Math_round(
      ConvertUnit(projectData.mainlinePumpPressure, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlinePumpHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePumpHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlineValveHeadloss: Math_round(
      ConvertUnit(projectData.mainlineValveHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlinePipe1Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    mainlinePipe2Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    mainlinePipe3Length: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Length, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    mainlinePipe1Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe1Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    mainlinePipe2Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe2Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    mainlinePipe3Dia: Math_round(
      ConvertUnit(+projectData.mainlinePipe3Dia, sourceUnits.pipeDiameter, METRIC_DEFAULTS.PipeDiameter, null),
      3,
    ),
    mainlineAllowedHeadloss: Math_round(
      ConvertUnit(projectData.mainlineAllowedHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlinePipeHeadloss: Math_round(
      ConvertUnit(projectData.mainlinePipeHeadloss, sourceUnits.pressure, METRIC_DEFAULTS.Pressure, null),
      3,
    ),
    mainlineLength: Math_round(
      ConvertUnit(projectData.mainlineLength, sourceUnits.length, METRIC_DEFAULTS.Length, null),
      3,
    ),
    mainlineMaxVelocity: Math_round(
      ConvertUnit(projectData.mainlineMaxVelocity, sourceUnits.velocity, METRIC_DEFAULTS.Velocity, null),
      3,
    ),
    zoneEmitterSpacing: Math_round(
      ConvertUnit(+projectData.zoneEmitterSpacing, sourceUnits.emitterSpacing, METRIC_DEFAULTS.EmitterSpacing, null),
      3,
    ),
    zoneLateralSpacing: Math_round(
      ConvertUnit(projectData.zoneLateralSpacing, sourceUnits.lateralSpacing, METRIC_DEFAULTS.LateralSpacing, null),
      3,
    ),
    zoneEmitterFlowRate: Math_round(
      ConvertUnit(projectData.zoneEmitterFlowRate, sourceUnits.flow, METRIC_DEFAULTS.Flow, null),
      3,
    ),
    zoneIrrigationArea: Math_round(
      ConvertUnit(+projectData.zoneIrrigationArea, sourceUnits.area, METRIC_DEFAULTS.Area, null),
      3,
    ),
    avgPeakConsumptiveUse: Math_round(
      ConvertUnit(+projectData.avgPeakConsumptiveUse, sourceUnits.appnDepth, METRIC_DEFAULTS.AppnDepth, null),
      3,
    ),
    sourceFlowRate: Math_round(
      ConvertUnit(+projectData.sourceFlowRate, sourceUnits.totalFlow, METRIC_DEFAULTS.TotalFlow, null),
      3,
    ),
  };
};
